<template>
  <div>
    <member-breadcrumb :title="title">
      <template slot="right-part">
        <el-button size="small" @click="$router.back()">返回</el-button>
      </template>
    </member-breadcrumb>
    <table class="myTable" cellspacing="0" cellpadding="0" border="0">
      <tr>
        <th>项目编号：</th>
        <td>{{info.id}}</td>
      </tr>
      <tr>
        <th>项目名称：</th>
        <td>{{info.projectName}}</td>
      </tr>
      <tr>
        <th>项目类型：</th>
        <td>{{ProjectTypeMap[info.projectType]}}</td>
      </tr>
      <tr>
        <th>项目预算：</th>
        <td>{{info.projectBudget/100}}元</td>
      </tr>
      <tr>
        <th>项目简介：</th>
        <td>{{info.projectIntro}}</td>
      </tr>
      <tr>
        <th>需求说明：</th>
        <td>
          <a :href="info.projectAttach" target="_blank" v-show="info.projectAttach"><i class="el-icon-paperclip"></i>需求说明</a>
        </td>
      </tr>
      <tr>
        <th>项目状态：</th>
        <td>{{ProjectFlagMap[info.flag]}}</td>
      </tr>
      <tr>
        <th>项目报价：</th>
        <td>
          <span v-if="info.price>0"> {{info.price/ 100}}元</span>
          <span v-else class="info">—</span>
        </td>
      </tr>
      <tr>
        <th>已付款项：</th>
        <td>
          <span v-if="info.moneyPaid>0"> {{info.moneyPaid/ 100}}元</span>
          <span v-else class="info">—</span>
        </td>
      </tr>
      <tr>
        <th>项目工期：</th>
        <td>
          <span v-if="info.devDays>0"> {{info.devDays}}天</span>
          <span v-else class="info">—</span>
        </td>
      </tr>
      <tr>
        <th>创建时间：</th>
        <td>{{dateUtil.format(info.createdAt)}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import MemberBreadcrumb from '../common/MemberBreadcrumb'
  import ProjectTypeMap from './ProjectTypeMap'
  import ProjectFlagMap from './ProjectFlagMap'
  import dateUtil from 'tdw-date-util'

  export default {
    name: 'ProjectDetail',
    components: {MemberBreadcrumb},
    data () {
      return {
        title: '项目详情',
        info: {},
        ProjectTypeMap,
        ProjectFlagMap,
        dateUtil
      }
    },
    beforeCreate () {
      const self = this
      self.$http.get({
        url: '/project/detail.do',
        data: {id: self.$route.params.id},
        success (res) {
          self.info = res.data
        }
      })
    }
  }
</script>

<style scoped>
  th {
    text-align: right;
    width: 150px;
    padding-right: 5px;
  }

  td {
    padding-left: 10px;
  }
</style>